interface TableCell {
  columnName: string;
  value: string | number;
}

interface Item {
  id: string;
  ean: string;
  key: string;
  done: boolean;
  amountNeeded: number;
  amountScanned: number;
  cells: TableCell[];
}

export const RgbStorageLocationSortingService = (a: Item, b: Item): number => {
  const getStorageLocationParts = (item: Item): number[] => {
    const storageLocation = item.cells.find((cell) => cell.columnName === "storage_location")?.value;

    if (typeof storageLocation !== "string") {
      return [1]; // Treat missing or invalid storage_location as 1
    }

    const sanitized = storageLocation.replace(/[a-zA-Z]/g, "");
    const parts = sanitized.split("-").map(Number);

    // Handle NaN parts gracefully
    return parts.map((part) => (isNaN(part) ? 1 : part));
  };

  const partsA = getStorageLocationParts(a);
  const partsB = getStorageLocationParts(b);

  const numA_A = partsA[0];
  const numB_A = partsB[0];

  if (numA_A < numB_A) return -1;
  if (numA_A > numB_A) return 1;

  const isOddA = numA_A % 2 === 1;
  const isOddB = numB_A % 2 === 1;

  const numA_B = partsA[1] || 1;
  const numB_B = partsB[1] || 1;

  if (isOddA && isOddB) {
    if (numA_B < numB_B) return -1;
    if (numA_B > numB_B) return 1;
  }

  if (!isOddA && !isOddB) {
    if (numA_B > numB_B) return -1;
    if (numA_B < numB_B) return 1;
  }

  const numA_C = partsA[2] || 1;
  const numB_C = partsB[2] || 1;

  if (numA_C < numB_C) return -1;
  if (numA_C > numB_C) return 1;

  return 0;
};